import React, {
 useRef, useContext, useEffect, useCallback,
} from 'react';
import MediaQuery from 'react-responsive';
import breakpoints from '../../../utilities/breakpoints';
import { convertSentenceToIdentifier, capitalLetters } from '../../../utilities/utilities';
import { ModalContext } from '../../../contexts/modalContext';
import FocusLock from 'react-focus-lock';
import './Modal.scss';

interface ModalProps {
  className: string;
  open: boolean;
  title: string;
  closeHandler: (event: React.SyntheticEvent) => void;
}

const Modal: React.FunctionComponent<React.PropsWithChildren<ModalProps>> = (
  props: React.PropsWithChildren<ModalProps>,
): React.ReactElement => {
  const { modalToBeScrolled, setModalToBeScrolled } = useContext(ModalContext);
  const modalRef = useRef<HTMLDivElement>(null);
  const modalRefElement = modalRef.current as Element;

  // Remember to clean up when removing it
  // window.removeEventListener('touchmove', preventDefault);

  const scrollModalToTop = useCallback(() => {
    if (modalRefElement !== null) {
      setModalToBeScrolled(false);

      // The scroll function doesn't work in ie11/edge, so we use scrollToo = 0 as a backup
      if (modalRefElement.scroll) {
        modalRefElement.scroll({ top: 0, behavior: 'smooth' });
      } else {
        modalRefElement.scrollTop = 0;
      }
    }
  }, [modalRefElement, setModalToBeScrolled]);

  const setFocusToModal = () => {
    const modalElement = document.getElementById(convertSentenceToIdentifier(props.title))
    if (modalElement) modalElement.focus();
  }

  useEffect(() => {
    if (modalToBeScrolled) {
      scrollModalToTop();
    }
  }, [modalToBeScrolled, scrollModalToTop]);

  let className = props.className ? props.className : 'modal';

  if (props.open) {
    className = className.concat(' open');
    scrollModalToTop();
    setFocusToModal();
    // const preventDefault = (e:any) => e.preventDefault();
    // // When rendering our container
    // document.body.addEventListener('touchmove', preventDefault, {
    //   passive: false
    // });
  }
  useEffect(() => {
    if (props.open) {
      className = className.concat(' open');
      scrollModalToTop();
      setFocusToModal();
    }
  });

  return (
    // We disable the a11y eslint rules for the clickable header because it is readable by a screen reader as-is
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <FocusLock disabled={!props.open}>
      <div
        id={convertSentenceToIdentifier(props.title)}
        className={`${convertSentenceToIdentifier(props.title)} ${className}`}
        onClick={props.closeHandler}
        ref={modalRef}
        tabIndex={props.open ? 0 : -1}
      >
        <div className="contents">
          <div className="heading">
            <MediaQuery maxDeviceWidth={breakpoints.mobileMaxWidth}>
              <h2>{props.title}</h2>
            </MediaQuery>
            <MediaQuery minDeviceWidth={breakpoints.mobileMaxWidth + 1}>
              <h1>{props.title}</h1>
            </MediaQuery>
            <button
              type="button"
              onClick={props.closeHandler}
              aria-label="Close"
              className="modal__close-button"
            />
          </div>
          <div className="text">{props.children}</div>
        </div>
      </div>
    </FocusLock>
  );
};

export default Modal;
