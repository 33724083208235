import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { UserSessionContext } from "../../contexts/userSessionContext";
import Card from "../common/Card/Card";
import Accordion from "../common/Accordion/Acordion";
import ErrorCard from "../common/ErrorCard/ErrorCard";
import ShowMoreList from "../common/ShowMoreList/ShowMoreList";
import ErrorBoundary from "../common/ErrorBoundary/ErrorBoundary";
import FullPageSpinner from "../common/FullPageSpinner/FullPageSpinner";
import WorkOrderOverview from "../common/WorkOrderOverview/WorkOrderOverview";
import LinkToModal from "../../components/common/LinkToModal/LinkToModal";
import { getActiveBPID, getAccountData } from "../../services/getAccountData"
import breakpoints from "../../utilities/breakpoints";
import {cacheHandler} from "../../utilities/cacheUtils";
import ProjectHeader from "./ProjectHeader/ProjectHeader";
import ProjectListItem from "./ProjectListItem/ProjectListItem";
import AccountManagerCard from "./AccountManagerCard/AccountManagerCard"
import "./AccountManagerCard/AccountManagerCard.scss";
import "./DashboardPage.scss";

interface filtersType {
  name: string;
  status: boolean;
}
interface filtersObjType {
  title: string;
  filters: filtersType[];
}
export type activeFiltersType = filtersObjType[]


const DashboardPage = () => {
  document.title = "CMC - Home";

  const { userSession }                 = useContext(UserSessionContext);

  let uid = "";
  userSession && (uid = userSession.user.uid);

  const [errorMessage, setErrorMessage] = useState("");

  const [activeBPID, setActiveBPID] = useState("");
  const [projectsList, setProjectsList] = useState([]);
  const [accountData, setAccountData] = useState(
    // { accountManager: { name: "", phone: "", email: ""}}
    );
  const [accountDataLoaded, setAccountDataLoaded] = useState(false)

  const plsFilterBy = (activeFilters: activeFiltersType) => {

    let cachedProjects = JSON.parse(cacheHandler.get('projectsList')) || []
    let serviceFilters = activeFilters.filter(f=>f.title==="Service Type")[0].filters.filter(f=>f.status)
    let stepFilters = activeFilters.filter(f=>f.title==="In Progress")[0].filters.filter(f=>f.status)
    if ([...serviceFilters, ...stepFilters].length < 1) {
      setProjectsList(cachedProjects)
    }

    let matchingProjects:any = []
    let matchingWorkOrders:any = []

    serviceFilters.forEach(sf => {
      matchingProjects = cachedProjects.filter((cp: { workOrders: any }) => {
        matchingWorkOrders = cp.workOrders.filter(
          (w: { service_type_label: string }) =>  w.service_type_label === sf.name)
        cp.workOrders = matchingWorkOrders
        return cp.workOrders.length > 0
      })
    })
    setProjectsList(matchingProjects)

    if (matchingProjects.length < 1) {
      matchingProjects = cachedProjects
    }

    stepFilters.forEach(sf => {
      matchingProjects = matchingProjects.filter((mp: { workOrders: any }) => {
        matchingWorkOrders = mp.workOrders.filter((w: { steps: any }) => {
          return w.steps.some((s:{step_label:string,step_status:string}) =>
            s.step_label === sf.name && s.step_status === "In Progress")
        })
        mp.workOrders = matchingWorkOrders
        return mp.workOrders.length > 0
      })
    })
    setProjectsList(matchingProjects)
  }

  interface businessType {
    id          : string;
    name        : string;
    accountCount: string;
    customerType: string;
  }

  // Need to determine activeBPID for account switcher
  
  !activeBPID && getActiveBPID(uid).then(id => {
    setActiveBPID(id)
    cacheHandler.set('ActiveBPID', id);
    sessionStorage.setItem('dataLoading', 'true')
  })

  activeBPID &&
  !accountData &&
  !errorMessage &&
  getAccountData(activeBPID)
  .then((data: any) => {
    setAccountData(data)
    setProjectsList(data.projectsList)
    sessionStorage.removeItem('dataLoading')
  })
  .catch(e => {
    setErrorMessage(e.message || "An error occurred");
  }).then(() => setAccountDataLoaded(true))

  let contents = null;

  if (!errorMessage && accountData) {
    const activeProjectContent = projectsList.map((item: any, k) => {
      const projectHeader = (
        <ProjectHeader
          premiseID={item.premiseID}
          projectName={item.projectName}
          projectAddress={item.projectAddress}
          userRole={item.userRole}
          completionDate=""
          key={item.premiseID}
        />
      );

      const workOrders =
        item.workOrders.length > 0 ? (
          item.workOrders.map((workorder: any) => {
            return (
              <ErrorBoundary msg="Invalid work order data" key={workorder.PWO + item.premiseID}>
                <Link className="work-order" to={`/work-order/${workorder.PWO}`}>
                  <WorkOrderOverview
                    key={workorder.PWO + item.premiseID}
                    workOrder={workorder}
                    linked
                  />
                </Link>
              </ErrorBoundary>
            );
          })
        ) : (
          <div className="no-work-orders-wrapper">
            There are no active work orders associated with this project.
          </div>
        );

      // We only want to render projects that have an array of work orders

      return (
        <ProjectListItem id={item.premiseID} premiseID={item.premiseID} key={item.premiseID+item.premiseID}>
          {/* At the desktop view, we simply display an expanded list */}
          <MediaQuery minDeviceWidth={breakpoints.tabletMaxWidth + 1}>
            <section
              className = "project-wrapper"
              key       = {item.premiseID}
            >
              {projectHeader}
              {workOrders}
            </section>
          </MediaQuery>
          {/* At the tablet and mobile view, we display an accordion */}
          <MediaQuery maxDeviceWidth={breakpoints.tabletMaxWidth}>
            <Accordion heading={projectHeader} includeChevron>
              <>
                {workOrders}
              </>
            </Accordion>
          </MediaQuery>
        </ProjectListItem>
      );
    });

    const bizNames = () => {
      let allBPs = JSON.parse(localStorage.getItem("cmcAllBPs") || "[]");
      return allBPs.length > 1
        ? allBPs.map((biz: any) => {
          console.log(biz.name)
            let line =
              biz.id === activeBPID ? (
                <div className="business-item__active" key={biz.id}>{biz.name}</div>
              ) : (
                <div
                  key={biz.id}
                  className = "business-item"
                  data-id   = {biz.id}
                  onClick   = {() => {
                    localStorage.setItem("cmcActiveBPID", biz.id);
                    window.location.reload();
                  }}
                  tabIndex={0}
                  onKeyUp={(e) => {if(e.keyCode === 13){
                    localStorage.setItem("cmcActiveBPID", biz.id);
                    window.location.reload();
                  }}}
                >
                  {biz.name}
                </div>
              );
            return line;
          })
        : null;
    };

    const businessSwitcher = (
      <div style={{marginBottom:".5rem"}}>
        <span>
          {cacheHandler.get("activeBPName") &&
            cacheHandler.get("activeBPName")}
          &nbsp;
        </span>
        <LinkToModal linkText="(Switch)" title="">
          {bizNames()}
        </LinkToModal>
      </div>
    );

    let getAccountManagerData = () => {
      const projects = JSON.parse(cacheHandler.get('projectsList') || "[]")
      const hasGas = projects.some((p: any) => {
        return p.serviceOrders.some((s: any) => {
          return s.serviceType === "Gas"
        })})

      if (accountData.contacts.accountManager && accountData.contacts.accountManager.name.length) {
        return <AccountManagerCard hasGas={hasGas || false} managerData={accountData.contacts.accountManager}/>
      } else {
        return (
          <>
            <Card className="account-manager-card">
              <div className="dte-account-manager-default">
                  <p>
                    For questions regarding your project, DTE project contact information can be found in steps 2, 3, and 7 of each work order.
                  </p>
              </div>
            </Card>
          </>
        )
      }
    }

    contents = (
      <>
        <div className="dashboard-page__welcome-heading-container">
          <h1 className="dashboard-page__welcome-heading">
            Hi, {userSession ? userSession.user.displayName : ""}
          </h1>
          <div className="dashboard-page__start-new-project-btn-wrapper">
          {/* the below is coming soon */}
          {/* <ButtonPrimary to="/">Start New Project</ButtonPrimary> */}
          </div>
        </div>
        {bizNames() && businessSwitcher}
        { getAccountManagerData() }
        <h3>Active Projects</h3>
        <ShowMoreList<any>
          includeSearch
          data={projectsList}
          IDPropName="premiseID"
          searchPlaceholder="Search by Work Order Number or Address"
          noResultsFoundText="No results can be found within our system. Please try again."
          className="dashboard-page__show-more-list"
          initialNumberDisplayed={10}
          incrementAmount={10}
          searchableKeys={["projectAddress", "workOrdersString"]}
          sortableKeys={[
            { key: "DEFAULT", label: "Sort by" },
            { key: "projectAddress", label: "Address" },
            { key: "last_updated", label: "Last Updated" },
            // { key: "completion_date", label: "Project Completion Date" },
            { key: "latestInProgress", label: "Status" },
          ]}
          plsFilterBy={plsFilterBy}
          scrollToTopButton
        >
          {activeProjectContent}
        </ShowMoreList>
      </>
    );
  } else if (errorMessage) {
    contents = (
      <ErrorCard>
        {errorMessage || "Sorry, there was a problem.  Please try again later."}
      </ErrorCard>
    );
  }

  return (
    <main className="dashboard-page narrow">
      { accountDataLoaded ? contents : <FullPageSpinner />}
    </main>
  );
};

export default DashboardPage;
