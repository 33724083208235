import React from "react";
import HowItWorks from "../common/HowItWorksCard/HowItWorksCard";
import ContactUsCard from "../common/ContactUsCard/ContactUsCard";
import WorkOrderProcessCard from "../common/WorkOrderProcessCard/WorkOrderProcessCard";
import QuestionOrConcernPopover from "../common/QuestionOrConcernPopover/QuestionOrConcernPopover"
import FAQCard from "./FAQCard/FAQCard";
import ChecklistsGuidesCard from "./ChecklistsGuidesCard/ChecklistsGuidesCard";
import {getCookie} from "../../utilities/cookieUtils"
import "./HelpResourcesPage.scss";

const HelpResourcePage = (): React.ReactElement => {

  document.title = "CMC - Help & Resources";

  console.log('env', process.env.REACT_APP_ENV)

  // getAccountData().then((data: any) => {
  //   console.log('here data');
  //   console.log(data)
  // })
  // .catch(e => {
  //   console.log("ERROR")
  // });

  // fetch(`${paths.getProjects}1200426177`, {
  //   credentials: 'include'
  // }).then(r => {
  //   if (!r.ok) {
  //     throw Error();
  //   }
  //   return r.json();
  // })
  // .then(json => {
  //   console.log('data');
  //   console.log(json);
  // })
  // .catch(e => {
  //   console.log("Error encountered!");
  // })


  // const u = "BP1101730833@DTEACCEPT.COM";
  // const p = "TEST1234";
  // let hackIframe = document.createElement("iframe");
  // hackIframe.style.display = "none"
  // hackIframe.src = `https://web-qa.dteco.com/wps/wcm/connect/dte-web-apps/hidden-pages/cmc_test?u=${u}&p=${p}`;
  // document.body.append(hackIframe);

  return (
    <>
      <div className="help-resources-page narrow">
        <main>
          <h1 className="help-resources-page__header">Help &amp; Resources</h1>
          <HowItWorks />
          <WorkOrderProcessCard />
          <div className="help-resources-page__checklists-and-contacts-container">
            <ChecklistsGuidesCard className="help-resources-page__checklists-guides-card" />
            <ContactUsCard className="help-resources-page__contact-us-card" />
          </div>
          <FAQCard />
        </main>
      </div>
      {/* {getCookie("webSecurityToken") && <QuestionOrConcernPopover />} */}
    </>
  );
};
export default HelpResourcePage;
