import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { paths } from "../../services/useAPI";
import {getMostRecentContact} from ".././../utilities/utilities"
import WorkOrderSteps from "./WorkOrderSteps/WorkOrderSteps";
import Card from "../common/Card/Card";
import FullPageSpinner from "../common/FullPageSpinner/FullPageSpinner";
import WorkOrderOverview from "../common/WorkOrderOverview/WorkOrderOverview";
import QuestionOrConcernPopover from "../common/QuestionOrConcernPopover/QuestionOrConcernPopover"
import AccountManagerCard from "../DashboardPage/AccountManagerCard/AccountManagerCard"
import "./WorkOrderPage.scss";
import { cacheHandler } from "../../utilities/cacheUtils";
import { getActiveBPID, getAccountData } from "../../services/getAccountData"
import { UserSessionContext } from "../../contexts/userSessionContext";



const WorkOrderPage: React.FC<{ match: any }> = ({
  match
}): React.ReactElement => {
  const initialPath = match.params.num
    ? paths.getWorkOrder + match.params.num
    : "";

  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
    
  const { userSession } = useContext(UserSessionContext);
  
  const checkLogIn = async () => {
    if(userSession) {
      let accountD = {} as any
      await getActiveBPID(userSession.user.uid).then(async id => {
        let bpID = id;
        await getAccountData(bpID).then(d => {
          accountD = d
        });
      })
      if(Object.keys(accountD).length && ('projectsList' in accountD) ) {
        return accountD.projectsList || []
      } else {
        return []
      }
      
    } else {
      return []
    }
  }
  const asyncSetData = async() => {
    await setParsedProjectsList(await checkLogIn());
  }
  const [projectsList] = useState(cacheHandler.get('ProjectsList') || '[]')
  const [parsedProjectsList, setParsedProjectsList] = useState(JSON.parse(projectsList))

  const getProjectListItem = (data:any) => {
    return parsedProjectsList.find( (projectsListItem: any) => {
      return projectsListItem.workOrdersString.includes(data.PWO)
    }) || []
  }

  const stringsFromPOList = () => {
    return parsedProjectsList.map((p : any)=> {
      return p.workOrdersString
    }).join(" ")
  }
  
  const stringCheck = async () => {    
    let strings = await stringsFromPOList()
    if (!strings.includes(match.params.num) && !errorMessage) {
      setErrorMessage("That work order number cannot be found in your projects")
      setLoading(false)
    } else {
  
      !data && !errorMessage && axios.get(initialPath, {withCredentials: true})
      .then(r => {
          const pListItem = getProjectListItem(r.data) || {};
          if(Object.keys(pListItem).length) {
            setData({...r.data, serviceOrders: pListItem.serviceOrders[0], workOrders: pListItem.workOrders })
          } else {
            setData(r.data)
          }
          setLoading(false)
          })
          .catch(e => {
            setErrorMessage( "Something went wrong")
            setLoading(false)
          })
          .then(() => {
            setLoading(false)
          })
    }
  }

  useEffect( () => {
    if(!parsedProjectsList.length) {
      asyncSetData()
    }
  }, [])

  useEffect(() => {
    if(parsedProjectsList.length) {
      stringCheck();
    }
  }, [parsedProjectsList])

  useEffect( () => {}, [data])
  
  const mostRecentContact = data && getMostRecentContact(data.steps, data.service_type_id)

  const display = !!data ? (
      <div>
        {
          data.workOrders && data.workOrders.length > 1 ?
          <>
          <WorkOrderOverview
            address="show"
            workOrder={data}
            woWithRelated={true}
          />
          {
            data.workOrders.map( (WO: any) => {
              if(WO.PWO !== data.PWO) {
                return <a href={`/work-order/${WO.PWO}`}><WorkOrderOverview
                    workOrder={WO}
                    relatedWO={true}
                    linked={true}
                /></a>
              } else return null;
            })
          }
        </>
         :
          <WorkOrderOverview
            address="show"
            workOrder={data}
          />
        }
        {mostRecentContact && <AccountManagerCard hasGas={false} managerData={mostRecentContact} />}
        <WorkOrderSteps data={data} />
      </div>
  ) : null
  const popover = !!data ? (
     <QuestionOrConcernPopover data={data}/>
  ) : null


  return (
    <main className="narrow wo-page">
      {!loading && display}
      {loading && match.params.num ? <FullPageSpinner /> : null}
      {errorMessage && match.params.num ? (
        <Card>
          {errorMessage && (
            <div className="wo-page__search--err">{errorMessage}</div>
          )}
        </Card>
      ) : null}
      {/* {popover} */}
    </main>
  );
};

export default WorkOrderPage;